import {
  differenceInHours,
  differenceInMinutes,
  format,
  isToday,
} from 'date-fns'
import { getInitials } from '../../lib/dealUtils'

export function formatDateTimeAgo(datetime: string): string {
  const date = new Date(datetime)
  if (isToday(date)) {
    const hoursDiff = differenceInHours(new Date(), date)

    if (hoursDiff < 1) {
      const minutesDiff = differenceInMinutes(new Date(), date)
      const minuteLabel = minutesDiff === 1 ? 'minute' : 'minutes'
      return `${minutesDiff} ${minuteLabel} ago`
    } else {
      const hourLabel = hoursDiff === 1 ? 'hour' : 'hours'
      return `${hoursDiff} ${hourLabel} ago`
    }
  } else {
    return format(date, 'MMM dd, yyyy')
  }
}

export function truncateString(str: string, num: number) {
  if (typeof str !== 'string' || !str) {
    return ''
  }
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export function getTeamPillLabel(teamName: string | null): string {
  if (typeof teamName !== 'string' || !teamName) {
    return ''
  }
  if (teamName.length < 4) {
    return teamName
  }

  return getInitials(teamName)
}
